import {
  PolicyType,
  type Hiring,
  InternalProposalStatus,
  type SendPolicyFormFields,
  type ContractFull,
} from "~/types";
import { proposalApi } from "~/api/proposal";
import { endorsementApi } from "~/api/endorsement";
import type { AxiosError } from "axios";
import type { SiriusError } from "~/utils/error";
import { useContractStore } from "../contract.store";
import { useTicketsStructure } from "./ticket.structure";
import { hiringApi } from "~/api";
import { HiringStatus } from "~/types/hiring";

export const definePolicyStore = (policyType: PolicyType) => () => {
  const contractStore = useContractStore();
  const { invalidateTables } = useTables();

  const contract = ref<ContractFull>();
  // Todas informações da apólice
  const hiring = ref<Hiring>();
  // ID do contrato vinculado
  const contractId = ref<number>();
  // Origem da apólice
  const policyOrigin = computed(() => {
    if (hiring.value?.endorsement) return hiring.value.endorsement.origin;
    return undefined;
  });

  const {
    analyzeTicketReceipt,
    createTicket,
    deleteTicket,
    deleteTicketReceipt,
    loadTickets,
    sendTicketReceipt,
    tickets,
    updateTicket,
  } = useTicketsStructure();

  // Reseta o scroll quando muda de status
  watch(
    () => hiring.value?.status,
    (newStatus, oldStatus) => {
      if (!oldStatus) return;

      const panelContent = document.querySelector(".panel__content");
      if (panelContent) {
        panelContent.scrollTop = 0;
      }
    }
  );

  const isLoadingHiring = ref(false);
  // Preenche o state com as informações da apólice
  async function loadHiring(_contractId: number, contractData?: ContractFull) {
    try {
      if (_contractId === 0) return;
      isLoadingHiring.value = true;

      if (contractData) {
        contract.value = contractData;
      }

      const { data } =
        (await hiringApi.contractHiring(
          _contractId,
          policyType,
          useNuxtApp().$auth.role.value
        )) || {};

      contractId.value = _contractId;

      if (!data) {
        isLoadingHiring.value = false;
        return;
      }

      const contract_quote = data.contract_quote;
      const proposal = data.contract_quote?.proposal;
      const endorsement =
        data.endorsement || data.contract_quote?.proposal?.endorsement;

      const needANewQuote = hiringUtils.needANewQuote({
        contract_quote,
        proposal,
        endorsement,
      });

      // Detalhes de cobertura (adicionado no front)
      const coverage_details =
        policyType === PolicyType.GERAL
          ? quoteCoverageDetails.rcg
          : quoteCoverageDetails.rcp;

      // Caso precise de uma cotação nova
      if (needANewQuote) {
        // Cria uma cotação utilizando o endpoint apropriado
        const quotation = await createQuote(_contractId, policyType);
        // Salva a cotação e o status de pendente
        hiring.value = {
          status: HiringStatus.PENDING,
          quote: {
            ...quotation,
            coverage_details,
          },
        };
        isLoadingHiring.value = false;
        return;
      }

      // Se já existir cotação mas não existir endosso vinculado
      if (contract_quote && !endorsement) {
        let status: HiringStatus = data.status;

        hiring.value = {
          status,
          quote: {
            ...contract_quote,
            coverage_details,
          },
          proposal: proposal || undefined,
        };
        isLoadingHiring.value = false;
        return;
      }

      // Se já existir endosso
      if (endorsement) {
        if (endorsement.origin === "internal") {
          // Carrega a estrutura dos boletos (quando é interno)
          // loadTickets(endorsement.id, endorsement.payments || []);
        }
        hiring.value = {
          status: data.status,
          quote: contract_quote
            ? {
                ...contract_quote,
                coverage_details,
              }
            : undefined,
          proposal: contract_quote
            ? contract_quote.proposal || undefined
            : undefined,
          endorsement,
        };
        isLoadingHiring.value = false;
        return;
      }
    } catch (_error) {
      const error = _error as AxiosError<SiriusError>;
      const errorData = error?.response?.data;
      // Tratamento para os erros
      if (errorData?.error_code) {
        // Apólice isenta por contrato isento
        if (errorData.error_code === "contract_does_not_require_policy") {
          hiring.value = {
            status: HiringStatus.EXEMPT_CONTRACT,
          };
          isLoadingHiring.value = false;
          return;
        }
        // Erros ocasionais (mostra pro usuário)
        if (canShowErrorForUser(_error)) {
          hiring.value = {
            status: HiringStatus.EMPTY,
          };
          isLoadingHiring.value = false;
          return;
        }
      }
      // Erros não previstos
      isLoadingHiring.value = false;
      hiring.value = {
        status: HiringStatus.EMPTY,
      };
      const errorMessage = `Erro ao buscar informações da apólice ${policyType}!`;
      useNuxtApp().$error({
        error,
        message: errorMessage,
        extra: {
          contractId: _contractId,
        },
      });
    }
  }

  async function createQuote(_contractId: number, policyType: PolicyType) {
    try {
      // Como não existe cotação ainda, cria uma cotação interna
      const quotation = await hiringApi.createQuote(
        _contractId,
        policyType,
        useNuxtApp().$auth.role.value
      );
      if (!quotation) throw new Error();
      // Reseta as tabelas de contratos ao criar uma nova cotação para um contrato
      await resetContractsTables();
      return quotation;
    } catch (error) {
      useNuxtApp().$error({
        error,
        message: "Erro ao criar cotação interna",
        extra: {
          contractId: _contractId,
          policyType,
        },
      });
      throw error;
    }
  }

  // Carrega a apólice com status de isenta
  function loadExemptPolicy() {
    hiring.value = {
      status: HiringStatus.EXEMPT_CONTRACT,
    };
  }

  // Carrega a apólice com status de isenta
  function loadPolicyForExpiredContract() {
    hiring.value = {
      status: HiringStatus.EMPTY,
    };
  }

  // Reseta a tabela de contratos
  async function resetContractsTables() {
    const companyId = contract.value?.company_id;
    await invalidateTables(({ queryNames }) => {
      return [
        queryNames.contracts,
        ...(companyId ? [queryNames.contractsByCompanyId(companyId)] : []),
      ];
    });
  }

  // Contrata a cotação
  async function contractQuotation() {
    try {
      if (!hiring.value?.quote) return;
      const response = await proposalApi.proposalByQuote(
        hiring.value.quote.id,
        useNuxtApp().$auth.role.value
      );
      hiring.value.proposal = response.data;
      await resetContractsTables();
    } catch (error) {
      if (canShowErrorForUser(error)) throw error;

      useNuxtApp().$error({
        error,
        message: "Erro ao contratar cotação!",
        extra: {
          quote: hiring.value?.quote,
        },
      });
      throw error;
    }
  }

  // Envia a apólice da cotação aprovada
  async function contractProposal(data: SendPolicyFormFields) {
    try {
      if (!hiring.value?.proposal) return;
      const response = await endorsementApi.createInternalEndorsement(
        {
          end_date: data.term_end || "",
          start_date: data.term_start || "",
          pdf_file: data.policy,
          endorsement_number: data.endorsement_number || "",
        },
        hiring.value?.proposal?.id,
        useNuxtApp().$auth.role.value
      );
      hiring.value.endorsement = response.data;

      // TODO: Adicionar estágio de análise no futuro
      hiring.value.status = HiringStatus.DONE;
      await resetContractsTables();
      await contractStore.loadHiringHistory();
      useNuxtApp().$toast("Apólice enviada!", {
        type: "success",
      });
    } catch (error) {
      if (canShowErrorForUser(error)) throw error;

      useNuxtApp().$error({
        error,
        message: "Erro ao enviar apólice!",
        extra: {
          proposal: hiring.value?.proposal,
        },
      });
      throw error;
    }
  }

  // Apaga o arquivo de endosso (apólice)
  async function deleteEndorsementFile(reason?: string) {
    try {
      if (!hiring.value?.endorsement) return;
      const { endorsement } = hiring.value;
      const endorsementId = endorsement.id;
      const endormentOrigin = endorsement.origin;
      if (endormentOrigin === "internal") {
        /**
         * Exclui toda instância do endosso utilizando a RECUSA,
         * voltando para o status de PENDING
         */
        await endorsementApi.evaluateEndorsement(
          endorsementId,
          "refused",
          reason || "Exclusão do arquivo"
        );
      } else if (endormentOrigin === "external") {
        await endorsementApi.deleteExternalEndorsement(endorsementId);
      }

      await loadHiring(contractId.value || 0);
      await contractStore.loadHiringHistory();

      await resetContractsTables();
      useNuxtApp().$toast("Apólice excluída!", {
        type: "warning",
      });
    } catch (error) {
      if (canShowErrorForUser(error)) throw error;
      useNuxtApp().$error({
        error,
        message: "Erro ao excluir apólice!",
        extra: {
          policy: hiring.value?.endorsement,
        },
      });
    }
  }

  // Substitui o arquivo da apólice
  async function replaceEndorsementFile(endorsementFile: File) {
    try {
      if (!hiring.value?.endorsement) return;
      const { endorsement } = hiring.value;
      const endorsementId = endorsement.id;
      const endorsementFileId =
        endorsement.endorsement_files[0].endorsement_file_id;
      // Apaga o arquivo do endosso
      await endorsementApi.deleteEndorsementFile(
        endorsementId,
        endorsementFileId
      );
      // Envia o novo arquivo do endosso
      const uploadResponse = await endorsementApi.uploadEndorsementFile(
        endorsementId,
        endorsementFile
      );
      hiring.value.endorsement = uploadResponse.data;
      await resetContractsTables();
      await contractStore.loadHiringHistory();
      useNuxtApp().$toast("Apólice substituída com sucesso!", {
        type: "success",
      });
    } catch (error) {
      if (canShowErrorForUser(error)) throw error;
      useNuxtApp().$error({
        error,
        message: "Erro ao substituir apólice!",
        extra: {
          policy: hiring.value?.endorsement,
        },
      });
    }
  }

  // Recusa a proposta de cotação
  async function refuseProposal() {
    try {
      if (!hiring.value) return;
      hiring.value.status = HiringStatus.PENDING;
      hiring.value.proposal = hiring.value.proposal
        ? {
            ...hiring.value.proposal,
            proposal_status: InternalProposalStatus.REFUSED,
          }
        : undefined;
      useNuxtApp().$toast("Proposta recusada!", {
        type: "warning",
      });
      await contractStore.loadHiringHistory();
    } catch (error) {
      useNuxtApp().$error({
        error,
        message: "Erro ao recusar proposta!",
      });
    }
  }

  // Limpa todas informações da apólice
  function clearPolicy() {
    hiring.value = undefined;
  }

  // Envia uma apólice externa
  async function sendUmbrella(data: SendPolicyFormFields) {
    const contract = contractStore.contract;
    const companyId = contract?.company_id;
    if (!companyId || !hiring.value) return;
    try {
      const response = await endorsementApi.createExternalEndorsementForCompany(
        companyId,
        {
          end_date: data.term_end || "",
          start_date: data.term_start || "",
          pdf_file: data.policy as File,
          policy_type: policyType,
          policy_number: data.endorsement_number || "",
          lmi: data.lmi || "",
        },
        useNuxtApp().$auth.role.value
      );
      hiring.value.endorsement = response.data;
      hiring.value.status = HiringStatus.ANALYSIS;
      await resetContractsTables();
      useNuxtApp().$toast("Apólice externa enviada, aguardando análise!", {
        type: "success",
      });
      await contractStore.loadHiringHistory();
    } catch (error) {
      if (canShowErrorForUser(error)) throw error;
      useNuxtApp().$error({
        error,
        message: "Erro ao enviar apólice externa!",
        extra: { companyId, policy: hiring.value?.endorsement },
      });
    }
  }

  // Aprova a apólice existente enviada
  async function approveUmbrella() {
    const endorsement = hiring.value?.endorsement;
    try {
      if (!endorsement || !hiring.value) return;
      const response = await endorsementApi.evaluateEndorsement(
        endorsement.id,
        "approved"
      );
      hiring.value.endorsement = response.data;
      hiring.value.status = HiringStatus.DONE;
      await resetContractsTables();
      useNuxtApp().$toast("Apólice aprovada!", {
        type: "success",
      });
      await contractStore.loadHiringHistory();
    } catch (error) {
      if (canShowErrorForUser(error)) throw error;
      useNuxtApp().$error({
        error,
        message: "Erro ao aprovar apólice!",
        extra: { policy: endorsement },
      });
    }
  }

  // Recusa a apólice existente enviada
  async function refuseUmbrella(reason?: string) {
    const endorsement = hiring.value?.endorsement;
    if (!endorsement || !hiring.value) return;
    try {
      await endorsementApi.evaluateEndorsement(
        endorsement.id,
        "refused",
        reason
      );
      await loadHiring(contractId.value || 0);
      await resetContractsTables();
      useNuxtApp().$toast("Apólice recusada!", {
        type: "warning",
      });
      await contractStore.loadHiringHistory();
    } catch (error) {
      if (canShowErrorForUser(error)) throw error;
      useNuxtApp().$error({
        error,
        message: "Erro ao recusar apólice!",
        extra: { policy: endorsement },
      });
    }
  }

  const showTickets = computed(() => {
    if (
      hiring.value?.endorsement &&
      hiring.value.endorsement.origin === "internal" &&
      hiring.value.status === HiringStatus.DONE
    ) {
      return true;
    }
    return false;
  });

  return {
    // Apólice
    hiring,
    policyOrigin,
    loadHiring,
    loadExemptPolicy,
    loadPolicyForExpiredContract,
    isLoadingHiring,
    clearPolicy,
    // Cotação
    contractQuotation,
    // Proposta
    refuseProposal,
    contractProposal,
    // Arquivo da apólice
    deleteEndorsementFile,
    replaceEndorsementFile,
    // Apólice externa
    sendUmbrella,
    approveUmbrella,
    refuseUmbrella,
    // Toda estrutura dos boletos
    analyzeTicketReceipt,
    createTicket,
    deleteTicket,
    deleteTicketReceipt,
    loadTickets,
    sendTicketReceipt,
    tickets,
    updateTicket,
    showTickets,
  };
};
