import Vue3Toastify, { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(Vue3Toastify, {
    autoClose: 2100,
    position: "top-right",
  });

  function success(message: string, delay?: number) {
    const handleToast = () =>
      toast(message, {
        type: "success",
        delay,
      });

    if (delay) {
      setTimeout(() => handleToast(), delay);
    } else {
      handleToast();
    }
  }

  return {
    provide: { toast, success },
  };
});
