import type { AxiosError } from "axios";
import { addHours } from "date-fns";
import { jwtDecode } from "jwt-decode";
import { authApi } from "~/api/auth";
import { useSupplierStore } from "~/stores";
import {
  UserRoles,
  type RequestTokenData,
  type Session,
  type UserActions,
  type LoginData,
  SessionSchema,
} from "~/types/auth";
import { isBefore, parseISO } from "date-fns";
import { ZodError } from "zod";
import { isMissingPhoneNumber, goToRegisterPhonePage } from "@/utils/auth";

export interface CheckUserAcessProps {
  roles?: UserRoles[] | UserRoles;
  actions?: UserActions[] | UserActions;
}

export const disconnectStatus = [401, 403];
export const LOCAL_STORAGE_NAME = "sirius-session";

export default defineNuxtPlugin({
  name: "auth",
  dependsOn: ["api"],
  async setup(nuxtApp) {
    const sessionOnLocalStorage = useLocalStorage<string | undefined>(
      LOCAL_STORAGE_NAME,
      undefined
    );

    const session = ref<Session | undefined>();

    const user = computed(() => session.value?.user);
    const role = computed(() => session.value?.user?.role);

    const queryClient = useQueryClient();
    const route = useRoute();
    const insurancesStore = useInsurancesStore();

    const setApiHeader = (token?: string) => {
      if (token) {
        useNuxtApp().$api.defaults.headers.common["authorization"] = token;
      } else {
        delete useNuxtApp()?.$api.defaults.headers.common["authorization"];
      }
    };

    // Verifica o formato do objeto de sessão salvo no localStorage
    function parseAndValidateSession(rawSession: string) {
      try {
        const validSession = SessionSchema.parse(JSON.parse(rawSession));
        return validSession as Session;
      } catch (error) {
        const _error = error as ZodError;
        console.error({
          error,
          message: "Erro ao carregar sessão",
          extra: { validationErrors: _error?.errors },
        });
      }
    }

    // Verifica se a sessão já foi expirada
    function isValidSessionAge(_session: Session) {
      try {
        const expireAt = parseISO(_session.expire_at);
        const isExpired = isBefore(expireAt, new Date());
        const isValidSession = !isExpired;
        return isValidSession;
      } catch (error) {
        return false;
      }
    }

    /**
     * Carrega a sessão do usuário caso exista
     */
    async function loadAuthentication() {
      // Verifica se existe sessão salva no localStorage
      if (sessionOnLocalStorage.value) {
        // Valida o formato do objeto da sessão
        const validSession = parseAndValidateSession(
          sessionOnLocalStorage.value
        );
        // Valida a data de expiração da sessão
        if (validSession && isValidSessionAge(validSession)) {
          session.value = validSession;
          setApiHeader(validSession.token);
          // Se for fornecedor carrega as suas informações
          if (validSession.user.role === UserRoles.SUPPLIER) {
            await useSupplierStore().loadSupplier();
            // Se estiver faltando telefone no fornecedor, encaminha ele para o formulário
            if (isMissingPhoneNumber(validSession.user)) {
              await goToRegisterPhonePage(route.path);
            } else {
              insurancesStore.createTimeoutAfterLogin();
            }
          }
        } else {
          setApiHeader();
          session.value = undefined;
          sessionOnLocalStorage.value = undefined;
        }
      } else {
        setApiHeader();
        session.value = undefined;
      }
    }

    loadAuthentication();

    /**
     * Verifica se o usuário está autenticado
     */
    const checkAuthentication = () => {
      return !!session.value;
    };

    /**
     * Envia requisição para pedir o email do token
     */
    const handleOperationalRequestToken = async (
      body: RequestTokenData,
      recaptcha: string
    ) => {
      try {
        const response = await authApi.requestToken(body, recaptcha);

        return response.data.token;
      } catch (_error) {
        const error = _error as AxiosError;
        const status = error.response?.status;
        if (status && status >= 400 && status <= 500) {
          useNuxtApp().$toast("Os dados são inválidos!", {
            type: "error",
          });
          throw _error;
        }
        useNuxtApp().$error({
          error,
          message: "Erro ao enviar token de login!",
          extra: {
            ...body,
          },
        });
        throw _error;
      }
    };

    /**
     * Envia requisição para pedir o email do token
     */
    const handleLogin = async (
      body: LoginData,
      recaptcha: string,
      throwError?: boolean
    ) => {
      try {
        const response = await authApi.login(body, recaptcha);
        const jwt = response.headers["authorization"];

        if (jwt) {
          const user = jwtDecode<{
            actions: UserActions[];
            email: string;
            role: UserRoles;
            cnpj?: string;
            name?: string;
            phone?: string;
          }>(jwt);

          // 12 Horas a partir de agora
          const expireDate = addHours(new Date(), 12).toISOString();

          if (user) {
            const _sessionData: Session = {
              token: jwt,
              user: {
                actions: user.actions,
                email: user.email,
                role: user.role,
                cnpj: user.cnpj,
                name: user.name,
                phone: user.phone,
              },
              expire_at: expireDate,
            };
            // Salva a sessão na memória e no localStorage
            sessionOnLocalStorage.value = JSON.stringify(_sessionData);
            session.value = _sessionData;
            setApiHeader(jwt);

            if (user.role === UserRoles.SUPPLIER) {
              // Se estiver faltando telefone no fornecedor, encaminha ele para o formulário
              if (isMissingPhoneNumber(_sessionData.user)) {
                await goToRegisterPhonePage(route.path);
              } else {
                insurancesStore.createTimeoutAfterLogin();
                await navigateTo("/contratos");
              }
              await useSupplierStore().loadSupplier();
            } else if (
              user.role === UserRoles.OPERATIONAL ||
              user.role === UserRoles.ADMIN
            ) {
              await navigateTo("/");
            }

            return body.user.token;
          }
        }
      } catch (_error) {
        const error = _error as AxiosError;
        const status = error.response?.status;
        if (status && status >= 500) {
          useNuxtApp().$error({
            error,
            message: "Erro ao realizar login!",
            extra: {
              ...body.user,
            },
          });
          if (throwError) throw _error;
          return false;
        }
        if (throwError) throw _error;
        return false;
      }
    };

    /**
     * Encerra a sessão
     */
    const handleLogout = async (onlyClearSession?: boolean) => {
      try {
        if (!onlyClearSession) {
          try {
            await authApi.logout();
          } catch (error) {
            console.error(error);
          }
        }
        session.value = undefined;
        sessionOnLocalStorage.value = undefined;
        setApiHeader();
        navigateTo("/entrar");
        setTimeout(() => {
          queryClient.invalidateQueries();
        }, 1500);
      } catch (error) {}
    };

    /**
     * Verifica se é permitido para o usuário
     */
    const checkUserAccess = ({ actions, roles }: CheckUserAcessProps) => {
      if (!roles && !actions) return true;
      if (!user.value) return false;

      if (roles) {
        if (Array.isArray(roles) && roles.length) {
          if (arrayUtils.existInArray(user.value.role, roles) === false)
            return false;
        } else if (typeof roles === "string") {
          if (user.value.role !== roles) return false;
        }
      }

      if (actions) {
        if (Array.isArray(actions) && actions.length) {
          for (const action of actions) {
            if (arrayUtils.existInArray(action, user.value.actions) === false)
              return false;
          }
        } else if (typeof actions === "string") {
          if (arrayUtils.existInArray(actions, user.value.actions) === false)
            return false;
        }
      }

      return true;
    };

    function updateSupplierPhone(phone: string) {
      if (!session.value) return;

      const newSessionData: Session = {
        ...session.value,
        user: { ...session.value.user, phone },
      };
      // Salva a sessão na memória e no localStorage
      sessionOnLocalStorage.value = JSON.stringify(newSessionData);
      session.value = newSessionData;
    }

    return {
      provide: {
        auth: {
          user,
          role,
          loadAuthentication,
          checkAuthentication,
          handleOperationalRequestToken,
          handleLogin,
          handleLogout,
          checkUserAccess,
          updateSupplierPhone,
        },
      },
    };
  },
});
