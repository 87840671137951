import { Insurances } from "~/types/insurances";
import * as insurancesIcons from "@/assets/images/icons/insurances";
import type { Option } from "~/types";

export interface InsuranceItem {
  title: string;
  selectTitle: string;
  key: Insurances;
  image: string;
  icon: string;
}

export const insurancesList: InsuranceItem[] = [
  {
    icon: insurancesIcons.business,
    title: "Seguro Empresarial",
    selectTitle: "Empresarial",
    image: "/images/insurances/business.png",
    key: Insurances.BUSINESS,
  },
  {
    icon: insurancesIcons.engineering,
    title: "Seguro Engenharia",
    selectTitle: "Engenharia",
    image: "/images/insurances/engineering.png",
    key: Insurances.ENGINEERING,
  },
  {
    icon: insurancesIcons.equipments,
    title: "Seguro Equipamentos",
    selectTitle: "Equipamentos",
    image: "/images/insurances/equipments.png",
    key: Insurances.EQUIPMENT,
  },
  {
    icon: insurancesIcons.warranty,
    title: "Seguro Garantia",
    selectTitle: "Garantia",
    image: "/images/insurances/warranty.png",
    key: Insurances.WARRANTY,
  },
  {
    icon: insurancesIcons.cyber,
    title: "Seguro Cyber",
    selectTitle: "Cyber",
    image: "/images/insurances/cyber.png",
    key: Insurances.CYBER,
  },
  {
    icon: insurancesIcons.auto,
    title: "Seguro Auto/Frota",
    selectTitle: "Auto/Frota",
    image: "/images/insurances/auto.png",
    key: Insurances.AUTO,
  },
];

export const insurancesOptions: Option[] = insurancesList.map((insurance) => ({
  code: insurance.key,
  label: insurance.selectTitle,
}));
