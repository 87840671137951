import { z } from "zod";

export enum Insurances {
  BUSINESS = "company_insurance",
  ENGINEERING = "engineering_insurance",
  EQUIPMENT = "equipment_insurance",
  WARRANTY = "warranty_insurance",
  CYBER = "cyber_insurance",
  AUTO = "auto_insurance",
}

export interface CreateInsuranceData {
  name: string;
  email: string;
  phone: string;
  hireables: Insurances[];
}

export const insurancesDisplayedSchema = z.array(z.string().min(1));
