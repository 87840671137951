<script setup lang="ts">
import VueSelect from "vue-select";
import type { Option } from "~/types";

const props = withDefaults(
  defineProps<{
    placeholder?: string;
    label?: string;
    options: Option[];
    disabled?: boolean;
    multiple?: boolean;
    clearable?: boolean;
    searchable?: boolean;
    modelValue?: string | string[];
    reduce?: (option: Option) => string;
    errorMessage?: string;
    small?: boolean;
    showErrorAway?: boolean;
    topLabel?: string;
  }>(),
  {
    clearable: true,
    searchable: true,
  }
);

const emit = defineEmits<{
  (e: "update:modelValue", value: string | string[]): void;
}>();

const innerValue = ref<Option[] | Option>();

const mergeValue = () => {
  if (typeof props.modelValue === "string") {
    const foundedOption = props.options.find(
      (option) => option.code === props.modelValue
    );
    if (foundedOption) {
      innerValue.value = foundedOption;
    }
  } else if (Array.isArray(props.modelValue)) {
    const foundedOptions: Option[] = [];
    for (const code of props.modelValue) {
      const option = props.options.find((op) => op.code === code);
      if (option) {
        foundedOptions.push(option);
      }
    }
    innerValue.value = foundedOptions;
  } else if (!props.modelValue) {
    innerValue.value = undefined;
  }
};

watch(
  () => props.modelValue,
  (opt) => {
    mergeValue();
  }
);

const changeValue = (option: string[] | string) => {
  emit("update:modelValue", option);
};

const searchValue = ref<string>("");
const observer = ref<IntersectionObserver | null>(null);
const limit = ref<number>(10);
const load = ref<HTMLLIElement | null>(null);

// Opções filtradas com o que foi digitado
const filteredOptions = computed(() => {
  // Tirar opção do select se ela ja foi selecionada
  const allOptions = props.options.filter((option) => {
    if (Array.isArray(props.modelValue)) {
      if (!!props.modelValue.find((opt) => opt === option.code)) {
        return false;
      }
    }
    return true;
  });
  if (searchValue.value === "") return allOptions;
  const normalizeSearch = stringUtils.normalizeString(searchValue.value);
  return allOptions.filter((option) => {
    return stringUtils.normalizeString(option.label).includes(normalizeSearch);
  });
});

// Opções paginadas (para não carregar 1 mil opções de uma vez)
const paginatedOptions = computed(() => {
  return filteredOptions.value.slice(0, limit.value);
});

// Verifica se pode carregar uma nova página de opções
const hasNextPage = computed<boolean>(() => {
  return paginatedOptions.value.length < filteredOptions.value.length;
});

// Lógica para carregar sempre mais opções
const infiniteScroll = async ([
  { isIntersecting, target },
]: IntersectionObserverEntry[]) => {
  if (isIntersecting) {
    const ul = (target as any).offsetParent as HTMLElement;
    const scrollTop = ul.scrollTop;
    limit.value += 10;
    await nextTick();
    ul.scrollTop = scrollTop;
  }
};

// Atribui observers assim que o select é aberto (lógica de infinitScroll)
const onOpen = async () => {
  if (hasNextPage.value) {
    await nextTick();
    if (load.value) {
      observer.value?.observe(load.value);
    }
  }
};

// Quando o select é fechado reseta a lista e tira o observer
const onClose = () => {
  observer.value?.disconnect();
  limit.value = 10;
};

// Cria o observer assim que o select é aberto
onMounted(() => {
  mergeValue();
  observer.value = new IntersectionObserver(infiniteScroll);
});
</script>

<template>
  <div
    :class="[
      'select-wrapper',
      small && 'small',
      multiple && 'multiple',
      disabled && 'disabled',
    ]"
  >
    <div class="input__wrapper">
      <div class="input__top-label" v-if="topLabel">
        <span>
          {{ topLabel }}
        </span>
      </div>
    </div>
    <VueSelect
      :options="paginatedOptions"
      :disabled="disabled"
      :label="label"
      :multiple="multiple"
      :placeholder="placeholder"
      :clearable="clearable"
      :filterable="false"
      :searchable="searchable"
      :class="['select-wrapper__input', errorMessage && FIELD_ERROR_CLASS]"
      :model-value="innerValue"
      :reduce="reduce"
      @update:model-value="changeValue"
      @search="(query) => (searchValue = query)"
      @open="onOpen"
      @close="onClose"
    >
      <template #list-footer>
        <li v-show="hasNextPage" ref="load" class="loader">
          Carregando opções...
        </li>
      </template>
      <template #no-options> Nenhuma opção encontrada. </template>
    </VueSelect>
    <div
      class="select-wrapper__error"
      v-show="showErrorAway === true ? true : !!errorMessage"
    >
      <span>{{ makeErrorMessage(errorMessage) }}</span>
    </div>
  </div>
</template>

<style lang="scss">
.vs__selected {
  @apply text-gray-dark;
}
.vs__dropdown-toggle {
  @apply border-shade-500 focus-within:border-shade-700;
}

.select-wrapper.disabled {
  .vs__search {
    visibility: hidden;
  }
}

.vs--single,
.vs--multiple {
  .vs__selected-options {
    @apply items-center;
  }
}

.select-wrapper:not(.multiple) {
  .vs--multiple {
    .vs__dropdown-toggle {
      @apply max-h-[48px] overflow-y-hidden;
    }
  }
}

.select-wrapper:not(.small) {
  .vs--single,
  .vs--multiple {
    .vs__selected-options {
      @apply items-center;
    }
    .vs__search,
    .vs__actions {
      @apply min-h-[42px];
    }

    .vs__selected {
      @apply h-[30px];
    }
    .vs__search {
      margin-top: 0 !important;
      padding-left: 14px;
    }
  }
}
.select-wrapper.small {
  .vs--single,
  .vs--multiple {
    .vs__dropdown-toggle,
    .vs__search,
    .vs__actions {
      @apply h-[34px];
    }

    .vs__dropdown-toggle {
      padding-bottom: 0 !important;
      align-items: flex-start;
    }
    .vs__actions {
      padding-top: 4px;
    }

    .vs__selected {
      margin: 0 !important;
      @apply h-[30px] pb-0 pl-2;
    }
    .vs__search {
      margin-top: 0 !important;
      padding-left: 14px;
    }
  }
}

.select-wrapper {
  @apply w-full flex flex-col;

  &__input {
    @apply w-full bg-white;
  }

  &__error {
    @apply w-full pl-3 text-left;
    span {
      @apply text-danger text-[14px] leading-[20px];
    }
  }
}

.input {
  &__wrapper {
    @apply flex flex-col;

    &__error {
      @apply w-full pl-3 text-left;
      span {
        @apply text-danger text-[14px] leading-[20px];
      }
    }
  }

  &__top-label {
    @apply font-[16px] leading-5 text-gray-dark pb-[7px];
  }
}
.loader {
  text-align: center;
  color: #bbbbbb;
}
</style>
