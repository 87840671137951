<script setup lang="ts">
import { onClickOutside, onKeyDown } from "@vueuse/core";
import { ref } from "vue";
import AtomsIcon from "../icon/index.vue";

const props = withDefaults(
  defineProps<{
    open?: boolean;
    closable?: boolean;
    size?: "small" | "medium" | "large";
  }>(),
  {
    open: false,
    closable: true,
    size: "large",
  }
);
const emit = defineEmits<{
  (e: "update:open", value: boolean): void;
}>();

const modal = ref<HTMLDivElement>();
const modalContent = ref<HTMLDivElement>();

const close = () => {
  if (!props.closable) return;
  emit("update:open", false);
};

onClickOutside(modalContent, close);
onKeyDown("Escape", close);

defineExpose({
  close,
});
</script>

<template>
  <div class="modal-full" ref="modal" v-if="open">
    <aside
      class="modal-full__content"
      ref="modalContent"
      :class="[
        size === 'small' && 'w-[640px] 2xl:w-[900px]',
        size === 'medium' && 'w-[1140px]',
        size === 'large' && 'w-[1200px] 2xl:w-[1418px]',
      ]"
    >
      <button
        class="modal-full__content__close cursor-pointer"
        type="button"
        @click="close"
        v-if="closable"
      >
        <AtomsIcon name="x" :size="24" class="text-primary" />
      </button>
      <div class="relative w-full flex flex-col">
        <slot></slot>
      </div>
    </aside>
  </div>
</template>

<style lang="scss">
.modal-full {
  @apply w-full h-[100vh] fixed left-0 top-0 z-50 bg-[rgba(0,0,0,0.6)]
  flex items-center justify-center animate-fadeIn px-8  overflow-auto;

  &__content {
    @apply max-w-full min-h-[40%] overflow-auto bg-white rounded-[8px] shadow-xl
    px-[24px] 2xl:px-[32px] py-[24px] 2xl:py-[32px] relative flex flex-col items-stretch;

    &__close {
      @apply h-[40px] w-[40px] flex items-center justify-center bg-transparent
      absolute top-2 lg:top-4 right-2 lg:right-4 z-10;
    }
  }
}
</style>
