import * as zod from "zod";
import { validateCNPJ } from "./validate";

export const validationMessages = {
  email: {
    default: "Insira um e-mail válido",
  },
  cnpj: {
    default: "Insira um CNPJ válido",
  },
  money: {
    default: "Insira um valor válido",
    min: "Precisa ser maior que R$ 0,00",
  },
  name: {
    default: "Insira um nome válido",
  },
  phone: {
    default: "Preencha com um telefone válido.",
  },
};

export const commonSchemas = {
  email: zod
    .string({
      required_error: validationMessages.email.default,
    })
    .email({ message: validationMessages.email.default })
    .min(1, { message: validationMessages.email.default }),
  cnpj: zod
    .string({
      required_error: validationMessages.cnpj.default,
    })
    .min(14, validationMessages.cnpj.default)
    .refine((cnpj) => validateCNPJ(cnpj), {
      message: validationMessages.cnpj.default,
    }),
  money: zod
    .string({
      required_error: validationMessages.money.default,
    })
    .min(1, { message: validationMessages.money.default })
    .refine((val) => val !== "0.00" && val !== "R$ 0,00", {
      message: validationMessages.money.min,
    }),
  phone: zod
    .string({ required_error: validationMessages.phone.default })
    .min(10, { message: validationMessages.phone.default })
    .max(11, { message: validationMessages.phone.default }),
  name: zod
    .string({ required_error: validationMessages.name.default })
    .min(1, validationMessages.name.default)
    .refine(
      (value) => !validateFullName(value),
      (value) => ({
        message: validateFullName(value),
      })
    ),
};
