import { z } from "zod";
import type { UserActions, UserRoles } from "~/constants/permissions";
export { UserActions, UserRoles } from "~/constants/permissions";

export interface Session {
  token: string;
  user: User;
  expire_at: string;
}

export interface User {
  name?: string;
  cnpj?: string;
  email: string;
  role: UserRoles;
  actions: UserActions[];
  phone?: string;
}

export interface RequestTokenData {
  user: {
    email: string;
    cnpj?: string;
  };
}

export interface LoginData {
  user: {
    // Quando for login por token de e-mail (operacional)
    token: string;
    // Quando for login direto (fornecedor)
    email?: string;
    cnpj?: string;
  };
}

// Schema para o User
const UserSchema = z.object({
  name: z.string().nullable().optional(),
  cnpj: z.string().nullable().optional(),
  phone: z.string().nullable().optional(),
  email: z
    .string()
    .email({ message: "O campo 'email' deve ser um e-mail válido." }),
  role: z.string().min(1, { message: "O campo 'role' é obrigatório." }),
  actions: z
    .array(z.string())
    .min(1, { message: "O campo 'actions' deve conter pelo menos uma ação." }),
});

// Schema para o Session
export const SessionSchema = z.object({
  token: z.string().min(1, { message: "O campo 'token' é obrigatório." }),
  user: UserSchema,
  expire_at: z.string().refine((date) => !isNaN(Date.parse(date)), {
    message: "O campo 'expire_at' deve ser uma data válida no formato ISO.",
  }),
});
