import { toTypedSchema } from "@vee-validate/zod";
import { useForm } from "vee-validate";
import { z } from "zod";
import { insurancesApi } from "~/api/insurances";
import type { CreateInsuranceData, Insurances } from "~/types";

export function useInsurancesForm() {
  const validation = z.object({
    phone: commonSchemas.phone,
    name: commonSchemas.name,
    email: commonSchemas.email,
    insurances: z
      .array(z.string({ required_error: validateMessages.required }), {
        required_error: validateMessages.required,
      })
      .min(1, validateMessages.required),
  });
  const validationSchema = toTypedSchema(validation);

  const {
    handleSubmit,
    errors,
    resetForm,
    defineField,
    setValues,
    isSubmitting: isLoading,
    setErrors,
    resetField,
  } = useForm({
    validationSchema,
  });

  const [phone] = defineField("phone");
  const [name] = defineField("name");
  const [email] = defineField("email");
  const [insurances] = defineField("insurances");

  const showForm = ref(false);

  watch(
    () => showForm.value,
    (_v) => {
      if (!_v) resetForm();
    }
  );

  const isSuccess = ref(false);

  const submit = handleSubmit(async (values) => {
    try {
      const data: CreateInsuranceData = {
        name: values.name,
        email: values.email,
        phone: values.phone,
        hireables: values.insurances as Insurances[],
      };
      await insurancesApi.createInsurance(data, useNuxtApp().$auth.role.value);
      useNuxtApp().$success("Solicitação enviada!");
      isSuccess.value = true;
    } catch (error) {
      if (canShowErrorForUser(error)) return;

      useNuxtApp().$error({
        error,
        message: "Erro ao enviar solicitação de seguro",
        extra: values,
      });
    }
  });

  return {
    errors,
    setValues,
    showForm,
    isLoading,
    submit,
    // Fields
    phone,
    name,
    email,
    insurances,
    isSuccess,
    setErrors,
    resetField,
  };
}
