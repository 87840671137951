<script setup lang="ts">
import { StarIcon } from "~/assets/images/icons";
import { Insurances } from "~/types";
import InsurancesForm from "@/components/molecules/insurances/form.vue";

const insurancesFormRef = ref<InstanceType<typeof InsurancesForm>>();

const { showHighlights } = storeToRefs(useInsurancesStore());

const highlightInsurancesList = insurancesList.filter((insurance) =>
  [Insurances.ENGINEERING, Insurances.BUSINESS, Insurances.EQUIPMENT].includes(
    insurance.key
  )
);
</script>

<template>
  <div>
    <AtomsModalFull v-model:open="showHighlights" size="medium">
      <div class="w-full flex flex-col gap-4">
        <div class="flex items-center text-primary gap-2">
          <StarIcon class="w-[24px]" />
          <span class="text-[24px] leading-5 font-bold">
            Confira nossos produtos em destaque
          </span>
        </div>
        <span class="text-[18px] leading-7">
          Que tal conhecer nossos outros seguros feitos sob medida para o seu
          negócio?
        </span>
        <div class="w-full grid grid-cols-3 gap-4">
          <AtomsInsurancesItem
            v-for="insurance in highlightInsurancesList"
            :key="insurance.key"
            :item="insurance"
            class="h-[178px]"
            @click="
              () => {
                showHighlights = false;
                insurancesFormRef?.openForm(insurance.key);
              }
            "
          />
        </div>
        <div class="w-full flex items-center justify-end">
          <AtomsButton
            label="Conferir"
            theme="primary"
            rounded
            class="w-[183px]"
            @click="
              () => {
                showHighlights = false;
                navigateTo({ name: 'outros-seguros' });
              }
            "
          />
        </div>
      </div>
    </AtomsModalFull>

    <!-- FORMULÁRIO DE SEGUROS -->
    <InsurancesForm
      ref="insurancesFormRef"
      @force-close="showHighlights = true"
    />
  </div>
</template>

<style lang="scss" scoped></style>
