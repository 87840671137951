import { type RouteRecordNameGeneric } from "vue-router";
import { insurancesDisplayedSchema, UserRoles, type User } from "~/types";

/**
 * Store para gerenciar o estado e lógica dos destaques de seguros
 * Utiliza o Pinia para gerenciamento de estado
 */
export const useInsurancesStore = defineStore("insurances-store", () => {
  // Estado base
  const route = useRoute();
  const showHighlights = ref(false);

  /**
   * Gerencia o armazenamento local dos dias em que os destaques foram exibidos
   * Utiliza localStorage para persistência dos dados
   */
  const daysDisplayedStorage = useLocalStorage(`h-displayed`, "[]");

  /**
   * Computed property que processa e valida os dias armazenados
   * @returns {string[]} Array com as datas de exibição
   */
  const daysDisplayed = computed<string[]>(() => {
    try {
      const displayed = insurancesDisplayedSchema.parse(
        JSON.parse(daysDisplayedStorage.value)
      );
      return displayed as string[];
    } catch (error) {
      return [];
    }
  });

  /**
   * Gera uma chave única para o usuário no dia atual
   * @param {User} user - Objeto do usuário
   * @returns {string} Chave no formato "email-[YYYY-MM-DD]"
   */
  function createKeyForUserToday(user: User): string {
    return `${user.email}-[${new Date().toISOString().slice(0, 10)}]`;
  }

  /**
   * Verifica se os destaques já foram exibidos para o usuário hoje
   * @returns {boolean | undefined} True se já foi exibido, undefined se não houver usuário
   */
  function isDisplayedTodayForUser(): boolean | undefined {
    const user = useNuxtApp().$auth.user.value;
    if (!user) return;
    const dayKey = createKeyForUserToday(user);
    return daysDisplayed.value.includes(dayKey);
  }

  /**
   * Registra que os destaques foram exibidos para o usuário hoje
   * Mantém apenas os últimos 20 registros
   */
  function setTodayIsDisplayedForUser(): void {
    const user = useNuxtApp().$auth.user.value;
    if (!user) return;
    const dayKey = createKeyForUserToday(user);
    daysDisplayedStorage.value = JSON.stringify(
      [dayKey, ...daysDisplayed.value].slice(0, 20)
    );
  }

  // Rotas onde os destaques não devem ser exibidos
  const excludedRouteNames: RouteRecordNameGeneric[] = ["outros-seguros"];

  /**
   * Gerencia a exibição dos destaques, verificando condições e timing
   * Implementa lógica de retry caso não seja possível exibir imediatamente
   */
  async function openHighlightsOrPostpone(): Promise<void> {
    const isAuthenticated = useNuxtApp().$auth.checkAuthentication();
    if (!isAuthenticated) return;

    const isSupplier = useNuxtApp().$auth.role.value === UserRoles.SUPPLIER;
    if (!isSupplier) return;

    if (isDisplayedTodayForUser()) return;

    const canOpenHighlights = !excludedRouteNames.includes(route.name);
    if (canOpenHighlights) {
      await delay(1000);
      showHighlights.value = true;
      setTodayIsDisplayedForUser();
    } else {
      const retryTime = 1000 * 15; // 15 segundos
      setTimeout(() => {
        openHighlightsOrPostpone();
      }, retryTime);
    }
  }

  /**
   * Configuração do timer para exibição automática após login
   * Valor padrão: 3 minutos (180 segundos)
   */
  const defaultSecondsTime = useLocalStorage(
    "h-seconds-to-open-after-login",
    String(60 * 3)
  );

  /**
   * Timer que controla a exibição automática dos destaques após o login
   */
  const loginTimer = useTimeout(Number(defaultSecondsTime.value) * 1000, {
    immediate: false,
    callback: () => {
      openHighlightsOrPostpone();
    },
    controls: true,
  });

  /**
   * Prepara o timer para exibição dos destaques após o login
   * Atualmente desativado (TODO)
   */
  function createTimeoutAfterLogin(): void {
    // TODO: Implementação futura
    // loginTimer.start();
  }

  // Interface pública da store
  return {
    createTimeoutAfterLogin,
    loginTimer,
    showHighlights,
    openHighlightsOrPostpone,
  };
});
