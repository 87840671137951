
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta } from "/app/pages/index.vue?macro=true";
import { default as indexukty56bEMVfZY08zLi_45nMJMsDhSnHjgpEFC7LExsZmoMeta } from "/app/pages/entrar/index.vue?macro=true";
import { default as indexD1ucp_kX6BHg0kqJsNgEUzV7l7TgxSE9F_45nQbzO2pVoMeta } from "/app/pages/empresas/index.vue?macro=true";
import { default as _91token_93NQy_45_45uvmts548K4_45ajpGBhjNhAqm7oAmCoFHgb_45_45p8wMeta } from "/app/pages/entrar/[token].vue?macro=true";
import { default as indexmtLKba1pqFhuRIq1P5sAiEJl_WPVb1nvU3EZJOlmGOQMeta } from "/app/pages/contratos/index.vue?macro=true";
import { default as indexSYWbPRbWQNAlgmUsy7wLnlxtlYKfziFxTSSs6I00lDIMeta } from "/app/pages/relatorios/index.vue?macro=true";
import { default as _91id_93WfFX6ptkt5vuyfBze0fV_4rlYnAqFQOtq9mULhs1t7wMeta } from "/app/pages/contratos/id/[id].vue?macro=true";
import { default as indexykI2yqGhLXtI2_BgMtL39J5YrRDROyFUEdAA6PYiK0gMeta } from "/app/pages/configuracoes/index.vue?macro=true";
import { default as _91cnpj_93WiQeZ4h4Z871Lmgh5UH1_U_sHQCgh4pdLLUgRVpvYBkMeta } from "/app/pages/empresas/cnpj/[cnpj].vue?macro=true";
import { default as index8gB6b0n8V1MVBaei_45mphMl03C2j2p4bS8Fmkz576WrgMeta } from "/app/pages/outros-seguros/index.vue?macro=true";
import { default as indexUmoVGSEKiL_452QsDo_TsZEO0DPEWJzspQ_45mUUNRnr5VAMeta } from "/app/pages/contratos/pendentes/index.vue?macro=true";
import { default as cadastrar_45telefoneDHryuXNBx8PZY_ua0Q7HE4ASXpafagmNo1t6ORpzxfIMeta } from "/app/pages/entrar/cadastrar-telefone.vue?macro=true";
import { default as indexyp6tgyP6b0ELkKesPe00t1J0bK0xNSNBF9LpHD4gXswMeta } from "/app/pages/simulador-de-calculo/index.vue?macro=true";
import { default as indexbu7kULqIA9li4KAxXh30PC_45F0M_G16Y3GSf0ou3zHuYMeta } from "/app/pages/configuracoes/empresas/index.vue?macro=true";
import { default as index1CiKQuNu9wr6fiHkEqYL2klCcp6mrAIodJlUZ1phzH8Meta } from "/app/pages/configuracoes/contratos/index.vue?macro=true";
import { default as indexbwCxxvr6Kw01VTtjhecY851cNzW2VIzBkFVo0UDKFg8Meta } from "/app/pages/configuracoes/propostas/index.vue?macro=true";
import { default as indexrB9PtT9G0ZHoVx9zOavAIR4dRwdKnXFeoCh6xB8jbGYMeta } from "/app/pages/configuracoes/endossos-internos/index.vue?macro=true";
import { default as index_9H_q7g3Cik_45tpEbxul_N1oGk2_45eWz9DaOVBi_TbLr4Meta } from "/app/pages/configuracoes/consulta-de-adjudicacoes/index.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    meta: indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta || {},
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "entrar",
    path: "/entrar",
    meta: indexukty56bEMVfZY08zLi_45nMJMsDhSnHjgpEFC7LExsZmoMeta || {},
    component: () => import("/app/pages/entrar/index.vue")
  },
  {
    name: "empresas",
    path: "/empresas",
    meta: indexD1ucp_kX6BHg0kqJsNgEUzV7l7TgxSE9F_45nQbzO2pVoMeta || {},
    component: () => import("/app/pages/empresas/index.vue")
  },
  {
    name: "entrar-token",
    path: "/entrar/:token()",
    meta: _91token_93NQy_45_45uvmts548K4_45ajpGBhjNhAqm7oAmCoFHgb_45_45p8wMeta || {},
    component: () => import("/app/pages/entrar/[token].vue")
  },
  {
    name: "contratos",
    path: "/contratos",
    meta: indexmtLKba1pqFhuRIq1P5sAiEJl_WPVb1nvU3EZJOlmGOQMeta || {},
    component: () => import("/app/pages/contratos/index.vue")
  },
  {
    name: "relatorios",
    path: "/relatorios",
    meta: indexSYWbPRbWQNAlgmUsy7wLnlxtlYKfziFxTSSs6I00lDIMeta || {},
    component: () => import("/app/pages/relatorios/index.vue")
  },
  {
    name: "contratos-id-id",
    path: "/contratos/id/:id()",
    meta: _91id_93WfFX6ptkt5vuyfBze0fV_4rlYnAqFQOtq9mULhs1t7wMeta || {},
    component: () => import("/app/pages/contratos/id/[id].vue")
  },
  {
    name: "configuracoes",
    path: "/configuracoes",
    meta: indexykI2yqGhLXtI2_BgMtL39J5YrRDROyFUEdAA6PYiK0gMeta || {},
    component: () => import("/app/pages/configuracoes/index.vue")
  },
  {
    name: "empresas-cnpj-cnpj",
    path: "/empresas/cnpj/:cnpj()",
    meta: _91cnpj_93WiQeZ4h4Z871Lmgh5UH1_U_sHQCgh4pdLLUgRVpvYBkMeta || {},
    component: () => import("/app/pages/empresas/cnpj/[cnpj].vue")
  },
  {
    name: "outros-seguros",
    path: "/outros-seguros",
    meta: index8gB6b0n8V1MVBaei_45mphMl03C2j2p4bS8Fmkz576WrgMeta || {},
    component: () => import("/app/pages/outros-seguros/index.vue")
  },
  {
    name: "contratos-pendentes",
    path: "/contratos/pendentes",
    meta: indexUmoVGSEKiL_452QsDo_TsZEO0DPEWJzspQ_45mUUNRnr5VAMeta || {},
    component: () => import("/app/pages/contratos/pendentes/index.vue")
  },
  {
    name: "entrar-cadastrar-telefone",
    path: "/entrar/cadastrar-telefone",
    meta: cadastrar_45telefoneDHryuXNBx8PZY_ua0Q7HE4ASXpafagmNo1t6ORpzxfIMeta || {},
    component: () => import("/app/pages/entrar/cadastrar-telefone.vue")
  },
  {
    name: "simulador-de-calculo",
    path: "/simulador-de-calculo",
    meta: indexyp6tgyP6b0ELkKesPe00t1J0bK0xNSNBF9LpHD4gXswMeta || {},
    component: () => import("/app/pages/simulador-de-calculo/index.vue")
  },
  {
    name: "configuracoes-empresas",
    path: "/configuracoes/empresas",
    meta: indexbu7kULqIA9li4KAxXh30PC_45F0M_G16Y3GSf0ou3zHuYMeta || {},
    component: () => import("/app/pages/configuracoes/empresas/index.vue")
  },
  {
    name: "configuracoes-contratos",
    path: "/configuracoes/contratos",
    meta: index1CiKQuNu9wr6fiHkEqYL2klCcp6mrAIodJlUZ1phzH8Meta || {},
    component: () => import("/app/pages/configuracoes/contratos/index.vue")
  },
  {
    name: "configuracoes-propostas",
    path: "/configuracoes/propostas",
    meta: indexbwCxxvr6Kw01VTtjhecY851cNzW2VIzBkFVo0UDKFg8Meta || {},
    component: () => import("/app/pages/configuracoes/propostas/index.vue")
  },
  {
    name: "configuracoes-endossos-internos",
    path: "/configuracoes/endossos-internos",
    meta: indexrB9PtT9G0ZHoVx9zOavAIR4dRwdKnXFeoCh6xB8jbGYMeta || {},
    component: () => import("/app/pages/configuracoes/endossos-internos/index.vue")
  },
  {
    name: "configuracoes-consulta-de-adjudicacoes",
    path: "/configuracoes/consulta-de-adjudicacoes",
    meta: index_9H_q7g3Cik_45tpEbxul_N1oGk2_45eWz9DaOVBi_TbLr4Meta || {},
    component: () => import("/app/pages/configuracoes/consulta-de-adjudicacoes/index.vue")
  }
]