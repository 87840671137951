import type { Pagination, Subgroup, UserRoles } from "@/types";
import { makePaginationRequest, makePaginationResponse } from "./paginate";
import type {
  ContractExemption,
  ContractFull,
  ContractSimple,
  HiringStatus,
  UpdateContractContact,
  QuoteSimulationData,
  QuoteSimulationResponse,
} from "~/types";
import { canExecuteRequest } from ".";
import type { PolicyExemptionFormFields } from "~/components/molecules/contract/exemption/form.vue";
type ListContracts = { contracts: ContractSimple[] };

export const contractApi = {
  // Lista todos os contratos (operacional)
  list(pagination: Pagination, role?: UserRoles, status?: HiringStatus) {
    if (!canExecuteRequest()) return null;
    return useNuxtApp()
      .$api.get<ListContracts>(
        `/${role}/contracts`,
        makePaginationRequest(pagination, {
          hiring_status: status,
        })
      )
      .then((response) =>
        makePaginationResponse<ContractSimple, ListContracts>(
          response,
          (data) => data.contracts
        )
      );
  },
  // Busca as informações de um contrato
  get(id: number | string, role?: UserRoles) {
    if (!canExecuteRequest()) return null;
    return useNuxtApp().$api.get<ContractFull>(`/${role}/contract/${id}`);
  },
  // Lista os contratos de uma empresa
  listFromCompany(
    pagination: Pagination,
    requestProps: { companyId: string | number },
    role?: UserRoles,
    status?: HiringStatus
  ) {
    if (!canExecuteRequest()) return null;
    return useNuxtApp()
      .$api.get<ListContracts>(
        `/${role}/company/contracts/${requestProps.companyId}`,
        makePaginationRequest(pagination, {
          hiring_status: status,
        })
      )
      .then((response) =>
        makePaginationResponse<ContractSimple, ListContracts>(
          response,
          (data) => data.contracts
        )
      );
  },
  // Isenta um contrato
  exemptContract(contractId: number, data: PolicyExemptionFormFields) {
    const formData = new FormData();
    formData.append("authorization_date", data.authorization_date);
    if (data.exemption_file) {
      formData.append("policy_exemption_file", data.exemption_file);
    }
    formData.append("reason", data.reason);

    return useNuxtApp().$api.post<ContractExemption>(
      `/operator/contracts/${contractId}/policy/exemption`,
      formData
    );
  },
  // Atualiza o contato de um contrato
  updateContact(
    id: number | string,
    data: UpdateContractContact,
    role?: UserRoles
  ) {
    if (!canExecuteRequest()) return null;
    return useNuxtApp().$api.patch<ContractFull>(
      `/${role}/contract/${id}/petitioner_area`,
      data
    );
  },
  // Cria uma simulação
  createSimulation(data: QuoteSimulationData, role?: UserRoles) {
    if (!canExecuteRequest()) return null;
    return useNuxtApp().$api.get<QuoteSimulationResponse>(
      `/${role}/contract_quotes/simulate`,
      {
        params: data,
      }
    );
  },
  // Carrega os subgrupos
  loadSubgroups(role?: UserRoles) {
    if (!canExecuteRequest()) return null;
    return useNuxtApp().$api.get<Subgroup[]>(`/${role}/subgroup`);
  },
};
