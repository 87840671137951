<script lang="ts" setup>
import type { Insurances } from "~/types/insurances";
import { useInsurancesForm } from "./form-actions";
import { CheckedIcon } from "@/assets/images/icons";

const emit = defineEmits<{
  (e: "force-close"): void;
}>();

const {
  errors,
  isLoading,
  phone,
  name,
  email,
  insurances,
  showForm,
  submit,
  setValues,
  isSuccess,
  resetField,
} = useInsurancesForm();

function openForm(insurance: Insurances) {
  showForm.value = true;
  isSuccess.value = false;
  setValues({
    insurances: [insurance],
  });
  setTimeout(() => {
    resetField("name");
    resetField("phone");
    resetField("email");
  }, 100);
}
defineExpose({ openForm });
</script>

<template>
  <AtomsModal
    v-model:open="showForm"
    title="Enviar solicitação"
    :scroll-content="isSuccess"
    :content-center="isSuccess"
    :closable="!isLoading"
    @force-close="emit('force-close')"
  >
    <!-- Mensagem de sucesso -->
    <div
      class="flex flex-col items-center justify-center gap-8"
      v-if="isSuccess"
    >
      <CheckedIcon class="w-[100px] text-green-500" />
      <span class="text-[24px] leading-[120%] text-gray-600 text-center">
        Solicitação enviada com sucesso!
      </span>
      <span class="text-[14px] leading-6 text-gray-600">
        Obrigado por escolher nossos produtos! Em breve um de nossos
        profissionais entrará em contato para fornecer mais detalhes.
      </span>
    </div>

    <!-- Formulário de solicitação -->
    <div class="flex flex-col gap-8" v-else-if="!isSuccess">
      <AtomsInput
        name="name"
        class="w-full animate-fadeIn"
        top-label="Nome"
        label="Nome completo"
        :error-message="errors.name"
        v-model="name"
      />
      <AtomsInput
        top-label="E-mail"
        v-model="email"
        :error-message="errors.email"
        class="w-full animate-fadeIn"
        name="email"
        inputmode="email"
        label="seu@melhoremail.com"
        autocomplete="on"
      />
      <AtomsInput
        name="phone"
        class="w-full animate-fadeIn"
        top-label="Telefone"
        label="Ex. (00) 00000-0000"
        :error-message="errors.phone"
        v-model="phone"
        mask="['(##) ####-####','(##) #####-####']"
      />
      <AtomsSelect
        :options="insurancesOptions"
        :error-message="errors.insurances"
        v-model="insurances"
        top-label="Seguros"
        placeholder="Selecione os que tem interesse"
        :reduce="(opt) => opt.code"
        :clearable="false"
        multiple
      />
    </div>

    <!-- Ações do formulário -->
    <template #actions>
      <div
        class="w-full flex items-center justify-center pt-8"
        v-if="isSuccess"
      >
        <AtomsButton
          rounded
          label="Fechar"
          theme="primary"
          class="w-[183px]"
          @click="
            () => {
              showForm = false;
            }
          "
        />
      </div>

      <div class="w-full flex items-center gap-6 pt-8" v-else-if="!isSuccess">
        <AtomsButton
          rounded
          link
          label="Cancelar"
          theme="primary"
          :disabled="isLoading"
          @click="
            () => {
              showForm = false;
              emit('force-close');
            }
          "
        />
        <AtomsButton
          rounded
          label="Enviar"
          theme="primary"
          :loading="isLoading"
          @click="submit"
        />
      </div>
    </template>
  </AtomsModal>
</template>

<style></style>
