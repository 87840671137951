export const stringUtils = {
  normalizeString(value: string) {
    return value
      .toLowerCase()
      .normalize("NFD")
      .replace(/[^a-zA-Z\s\d]/g, "");
  },
  capitalizeFirstChar(input: string): string {
    if (!input) return "";
    return input.charAt(0).toUpperCase() + input.slice(1);
  },
};
