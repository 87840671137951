<script setup lang="ts">
import { makeErrorMessage } from "../../../utils/error";
import { Money3Component } from "v-money3";
import Icon from "../icon/index.vue";

const props = withDefaults(
  defineProps<{
    type?: "number" | "text" | "password" | "date";
    label?: string;
    icon?: string;
    iconColorClass?: string;
    modelValue?: string;
    disabled?: boolean;
    errorMessage?: string;
    name?: string;
    inputmode?:
      | "text"
      | "numeric"
      | "decimal"
      | "tel"
      | "search"
      | "email"
      | "url";
    mask?: string | string[];
    useMaskedValue?: boolean;
    autocomplete?: "on" | "off";
    small?: boolean;
    money?: boolean;
    topLabel?: string;
    clearable?: boolean;
  }>(),
  {
    type: "text",
    iconColorClass: "text-gray-dark",
    mask: "",
    autocomplete: "on",
  }
);

const emit = defineEmits<{
  (e: "update:modelValue", value: string): void;
}>();

const lastValue = ref(props.modelValue || "");
watch(
  () => props.modelValue,
  (_v) => (lastValue.value = _v || "")
);

const handleChangeValue = (
  event: CustomEvent<{ masked: string; unmasked: string; completed: boolean }>
) => {
  if (props.disabled) return;
  let value =
    props.useMaskedValue || props.money
      ? event.detail.masked
      : event.detail.unmasked;

  if (props.money) {
    value = value.replace("R$ ", "");
  }

  // Não deixa emitir duas vezes
  if (value === lastValue.value) return;

  lastValue.value = value;

  emit("update:modelValue", value);
};

const changeMoneyValue = (val: string | number) => {
  if (typeof val === "number") {
    val = String(val);
  }
  lastValue.value = val;
  emit("update:modelValue", val);
};
</script>

<template>
  <div class="input__wrapper">
    <div class="input__top-label" v-if="topLabel">
      <span>
        {{ topLabel }}
      </span>
    </div>
    <div
      class="input"
      :class="{ disabled, small, icon: !!icon || !!$slots['left'] }"
    >
      <slot name="left">
        <Icon
          class="input__icon"
          :name="icon"
          v-if="icon"
          :class="[iconColorClass, 'min-w-[20px]']"
        />
      </slot>
      <input
        v-if="!money"
        :class="['input__writer', errorMessage && FIELD_ERROR_CLASS]"
        :type="type"
        :placeholder="label"
        :value="modelValue"
        :disabled="disabled"
        :name="name"
        :inputmode="inputmode"
        :max="type === 'date' ? '2999-12-31' : undefined"
        v-maska
        :data-maska="mask"
        :data-maska-tokens="undefined"
        :autocomplete="autocomplete"
        @maska="handleChangeValue"
      />
      <Money3Component
        v-else
        :class="['input__writer', errorMessage && FIELD_ERROR_CLASS]"
        :type="type"
        :placeholder="label"
        :value="modelValue"
        :disabled="disabled"
        :name="name"
        :inputmode="inputmode"
        :model-value="modelValue || ''"
        decimal=","
        thousands="."
        prefix="R$ "
        :precision="2"
        @update:model-value="changeMoneyValue"
      />
      <slot name="right">
        <div
          :class="[
            'flex pl-3 items-center justify-center',
            small ? 'h-[32px]' : 'h-[44px]',
          ]"
        >
          <button
            v-if="clearable && modelValue"
            @click="emit('update:modelValue', '')"
            :class="[
              'flex items-center justify-center',
              small ? 'h-[32px]' : 'h-[44px]',
            ]"
          >
            <AtomsIcon name="x" :size="22" class="text-danger-dark" />
          </button>
        </div>
      </slot>
    </div>
    <div class="input__wrapper__error" v-if="errorMessage">
      <span>{{ makeErrorMessage(errorMessage) }}</span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.input {
  &__wrapper {
    @apply flex flex-col;

    &__error {
      @apply w-full pl-3 text-left;
      span {
        @apply text-danger text-[14px] leading-[20px];
      }
    }
  }

  &__top-label {
    @apply font-[16px] leading-5 text-gray-dark pb-[7px];
  }

  @apply bg-white flex items-center border border-shade-500 focus-within:border-shade-700 transition rounded-lg px-[10px];

  &.disabled {
    @apply cursor-not-allowed contrast-[.9];
    .input__writer {
      @apply cursor-not-allowed;
    }
  }

  &__icon {
    @apply text-[20px];
  }
  &__writer {
    @apply w-full h-[44px] pr-[10px] pl-2 bg-transparent border-0 outline-0 text-gray-dark placeholder:text-[#adadad] tracking-[0.04em];
  }

  &:not(.small) {
    .input__writer {
      @apply h-[44px] pr-[10px] pl-2;
    }
  }

  &.small {
    &.icon {
      @apply pl-1;
    }
    @apply px-0;
    .input__writer {
      @apply h-[32px] pr-[0] pl-[10px];
    }
  }
}
</style>
