import type { Endorsement } from "~/types/endorsement";
import { type Pagination, UserRoles } from "@/types";
import { makePaginationRequest, makePaginationResponse } from "./paginate";
import type { ContractSimple, PolicyType } from "~/types";
import { canExecuteRequest } from ".";

interface CreateInternalEndorsement {
  pdf_file: File;
  start_date: string;
  end_date: string;
  endorsement_number: string;
}
type ListUmbrellas = Endorsement[];

export interface CreateExternalEndorsement {
  pdf_file: File;
  start_date: string;
  end_date: string;
  policy_number: string;
  policy_type: PolicyType;
  lmi: string;
}

export const endorsementApi = {
  createInternalEndorsement(
    data: CreateInternalEndorsement,
    proposalId: number,
    role?: UserRoles
  ) {
    const formData = new FormData();
    formData.append("endorsement_pdf_file", data.pdf_file);
    formData.append("endorsement[start_date]", data.start_date);
    formData.append("endorsement[end_date]", data.end_date);
    formData.append("endorsement[endorsement_number]", data.endorsement_number);

    return useNuxtApp().$api.post<Endorsement>(
      `/${role}/endorsement/${proposalId}/create_internal`,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
  },
  /**
   * Apaga o arquivo do endosso
   */
  deleteEndorsementFile(endorsementId: number, endorsementFileId: string) {
    return useNuxtApp().$api.delete(
      `/operator/endorsement/${endorsementId}/delete_file`,
      {
        data: {
          endorsement: {
            endorsement_file_id: endorsementFileId,
          },
        },
      }
    );
  },
  /**
   * Aprova ou recusa uma apólice enviada
   * OBS: Se recusada limpa toda instância da apólice, voltando pra cotação
   */
  evaluateEndorsement(
    endorsementId: number,
    status: "approved" | "refused",
    description?: string
  ) {
    return useNuxtApp().$api.put<Endorsement>(
      `/operator/endorsement/${endorsementId}/evaluate_endorsement`,
      {
        endorsement: {
          policy_status: status,
          policy_status_description: description,
        },
      }
    );
  },
  /**
   * Envia uma nova apólice
   */
  uploadEndorsementFile(endorsementId: number, endorsementFile: File) {
    const formData = new FormData();
    formData.append("endorsement_pdf_file", endorsementFile);

    return useNuxtApp().$api.post<Endorsement>(
      `/operator/endorsement/${endorsementId}/upload_file`,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
  },
  /**
   * Lista os endossos externos de uma empresa
   */
  listExternalEndorsements(
    pagination: Pagination,
    companyId: number,
    role?: UserRoles
  ) {
    if (!canExecuteRequest()) return null;
    return useNuxtApp()
      .$api.get<ListUmbrellas>(
        `/${role}/endorsements/${companyId}/show_external`,
        makePaginationRequest(pagination)
      )
      .then((response) =>
        makePaginationResponse<Endorsement, ListUmbrellas>(response, (data) =>
          data.filter((item) => item.origin === "external")
        )
      );
  },
  /**
   * Cria um endosso externo para uma empresa
   */
  createExternalEndorsementForCompany(
    companyId: number,
    data: CreateExternalEndorsement,
    role?: UserRoles
  ) {
    const formData = new FormData();
    formData.append("endorsement_pdf_file", data.pdf_file);
    formData.append("endorsement[start_date]", data.start_date);
    formData.append("endorsement[end_date]", data.end_date);
    formData.append("endorsement[policy_type]", data.policy_type);
    formData.append("endorsement[policy_number]", data.policy_number);
    formData.append("endorsement[lmi]", data.lmi);

    return useNuxtApp().$api.post<Endorsement>(
      `/${role}/endorsement/${companyId}/create_external_umbrella_policy`,
      formData
    );
  },
  /**
   * Apaga um endosso externo
   */
  deleteExternalEndorsement(endorsementId: number) {
    return useNuxtApp().$api.delete(
      `/operator/endorsement/${endorsementId}/delete_external_endorsement`
    );
  },
  /**
   * Lista os contratos vinculados a um endosso externo
   */
  listEndorsementsContracts(endorsementId: number, role?: UserRoles) {
    if (!canExecuteRequest()) return null;
    return useNuxtApp().$api.get<{ contracts: ContractSimple[] }>(
      `/${role}/endorsement/contracts/${endorsementId}`
    );
  },
};
