export const tableQueries = {
  admin: {
    companies: "companies-admin",
    contracts: "contracts-admin",
    excels: "excels-admin",
    internalEndorsements: "internal-endorsements-admin",
    proposals: "proposals-admin",
  },
  companies: "companies",
  contracts: "contracts",
  contractsByCompanyId: (companyId?: number) => `contracts-${companyId}`,
  companyUsers: (companyId?: number) => `company-users-${companyId}`,
};

export function useTables() {
  const queryClient = useQueryClient();

  // Recarrega tabelas
  function refetchTables(
    selector: (params: { queryNames: typeof tableQueries }) => string
  ) {
    const queryNames = selector({ queryNames: tableQueries });
    return queryClient.refetchQueries({
      queryKey: Array.isArray(queryNames) ? queryNames : [queryNames],
    });
  }

  // Invalida o conteúdo de uma tabela, assim que for acessada a tela, o conteúdo é carregado novamente
  function invalidateTables(
    selector: (params: { queryNames: typeof tableQueries }) => string | string[]
  ) {
    const queryNames = selector({ queryNames: tableQueries });
    return queryClient.invalidateQueries({
      queryKey: Array.isArray(queryNames) ? queryNames : [queryNames],
    });
  }

  return { refetchTables, invalidateTables };
}
