import { UserRoles, type User } from "~/types";

export const getRoleName = (role: UserRoles) => {
  return (
    (
      {
        [UserRoles.OPERATIONAL]: "Operador",
        [UserRoles.ADMIN]: "Administrador",
        [UserRoles.SUPPLIER]: "Fornecedor da Telefonica",
      } as Record<UserRoles, string>
    )[role] || undefined
  );
};

export const REGISTER_PHONE_PAGE = "/entrar/cadastrar-telefone";
// Verifica se o usuário fornecedor não cadastrou o telefone ainda
export function isMissingPhoneNumber(_user: User) {
  return !_user.phone;
}
export function goToRegisterPhonePage(atualPath?: string) {
  if (atualPath && atualPath === REGISTER_PHONE_PAGE) {
    return;
  }
  return navigateTo(REGISTER_PHONE_PAGE);
}
