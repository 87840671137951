import type { RefinementCtx } from "zod";

/**
 * Valida campo CPF
 */
export function validateCPF(cpf?: string): boolean {
  if (!cpf) return false;
  cpf = cpf.replace(/[^\d]+/g, "");
  if (cpf === "") return false;
  if (
    cpf.length !== 11 ||
    cpf === "00000000000" ||
    cpf === "11111111111" ||
    cpf === "22222222222" ||
    cpf === "33333333333" ||
    cpf === "44444444444" ||
    cpf === "55555555555" ||
    cpf === "66666666666" ||
    cpf === "77777777777" ||
    cpf === "88888888888" ||
    cpf === "99999999999"
  )
    return false;
  // Valida 1o digito
  let add = 0;
  for (let i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i);
  let rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== parseInt(cpf.charAt(9))) return false;
  // Valida 2o digito
  add = 0;
  for (let i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i);
  rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== parseInt(cpf.charAt(10))) return false;
  return true;
}

/**
 * Valida campo CNPJ
 */
export function validateCNPJ(cnpj: string, isInsuredCnpj = false) {
  cnpj = String(cnpj).replace(/[^\d]+/g, "");
  //caso seja o cnpj do segurado (custom_data do fiscal) retorna true quando o cnpj for vazio
  if (cnpj === "" && isInsuredCnpj) return true;

  if (cnpj === "") return false;

  if (cnpj.length !== 14) return false;

  // Elimina CNPJs invalidos conhecidos
  if (
    cnpj === "00000000000000" ||
    cnpj === "11111111111111" ||
    cnpj === "22222222222222" ||
    cnpj === "33333333333333" ||
    cnpj === "44444444444444" ||
    cnpj === "55555555555555" ||
    cnpj === "66666666666666" ||
    cnpj === "77777777777777" ||
    cnpj === "88888888888888" ||
    cnpj === "99999999999999"
  )
    return false;

  // Valida DVs
  let tamanho = cnpj.length - 2;
  let numeros = cnpj.substring(0, tamanho);
  const digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += Number(numeros.charAt(tamanho - i)) * pos--;
    if (pos < 2) pos = 9;
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado !== Number.parseInt(digitos.charAt(0))) return false;

  tamanho = tamanho + 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += Number(numeros.charAt(tamanho - i)) * pos--;
    if (pos < 2) pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  return resultado === Number.parseInt(digitos.charAt(1));
}

export const validateFullName = (
  name: string,
  allowOneName?: boolean
): string | undefined => {
  if (!name) return "Campo obrigatório";
  const invalidChars = name.replace(/[a-z\sçáàâãéèêíìîóòôõúùû.'0-9&]/gi, "");
  if (invalidChars.length > 0) {
    return `Caracteres inválidos: ${arrayUtils
      .removeDuplicates<string>(invalidChars.split(""))
      .join(" ")}`;
  }
  name = name.replace(/[^a-z\sçáàâãéèêíìîóòôõúùû.'0-9&]/gi, "").trim();
  if (name === "") return "Campo obrigatório";
  const nameSplit = name.split(" ");
  // precisa que o primeiro nome exista
  if (nameSplit.length === 0 || nameSplit[0] === "")
    return "Preencha com um nome válido";
  // precisa que o primeiro nome tenha no minimo 2 letras (YU)
  if (nameSplit[0].length < 2)
    return "O nome precisa ter no mínimo 2 caracteres";
  // caso tenha o parametro que permite apenas um nome, deixa passar
  if (nameSplit.length === 1 && allowOneName) return;
  else if (nameSplit.length === 1 && !allowOneName)
    return "Preencha o sobrenome";
  // obriga que o segundo nome tenha no minimo 1 letra
  if (nameSplit.length > 1 && nameSplit[1].length < 1)
    return "O sobrenome precisa ter no mínimo 1 letra";
  return;
};

export const validateMessages = {
  required: "Campo obrigatório.",
  email: "Insira um e-mail válido.",
};

export function validateMoney(value: string, ctx: RefinementCtx) {
  if (value === "0.00") {
    ctx.addIssue({
      code: "custom",
      message: validationMessages.money.min,
    });
  } else if (value.includes("-")) {
    ctx.addIssue({
      code: "custom",
      message: "O valor do contrato precisa ser maior que zero",
    });
  }
}
