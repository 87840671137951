import { type InternalProposal, type Endorsement, HiringStatus } from "@/types";
import type { InternalQuotation } from "~/types/quotation";

export const hiringUtils = {
  // Precisa de uma nova cotação?
  needANewQuote({
    contract_quote,
    endorsement,
    proposal,
  }: {
    contract_quote?: InternalQuotation | null;
    proposal?: InternalProposal | null;
    endorsement?: Endorsement | null;
  }) {
    // Se não existir endosso ou cotação
    if (!contract_quote && !endorsement) {
      return true;
    }
    return false;
  },
};

export const hiringStatusList: { [key in HiringStatus]?: string } = {
  [HiringStatus.PENDING]: "Pendência",
  [HiringStatus.ANALYSIS]: "Analisar",
  [HiringStatus.EMISSION]: "Emitir",
  [HiringStatus.RENEWAL]: "Renovação",
  [HiringStatus.DONE]: "Resolvido",
  [HiringStatus.FINISHED]: "Finalizado",
  [HiringStatus.EXEMPT_CONTRACT]: "Isento",
  [HiringStatus.ERROR]: "Erro",
};
